<template>
  <b-overlay :show="showOverlay">
    <b-card v-if="faq" class="data-edit-wrapper">
      <h2>Faq</h2>
      <b-form class="mt-2">
        <validation-observer ref="updateFAQ" tag="div">
          <b-row>
            <b-col class="" md="6">
              <b-form-group
                  label="Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="faq.title"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  class=""
                  label="faq type"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <v-select
                      v-model="faq.faqType"
                      :class="{'border-danger rounded':errors.length > 0 }"
                      :options="faqTypes"
                      :reduce="name=> name.value"
                      dir="ltr"
                      label="name"

                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  class=""
                  label="Panel"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <v-select
                      v-model="faq.panelType"
                      :class="{'border-danger rounded':errors.length > 0 }"
                      :options="panelTypes"
                      :reduce="name=> name.value"

                      dir="ltr"
                      label="name"


                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="faq" cols="12" md="12">
              <h2>Content</h2>
              <ckEditorMain ref="editorContent" :defaultContent="faq.description" @getEditorContent="setEditorContent"
              ></ckEditorMain>
            </b-col>
            <b-col class="my-3" cols="12" md="2">
              <b-button class="btn " type="button" variant="primary" @click="updateFAQ">Submit</b-button>
            </b-col>
          </b-row>
        </validation-observer>

      </b-form>
    </b-card>

  </b-overlay>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import { BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BOverlay, BRow, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Helper from '@/libs/Helper'
import { GetFAQById, UpdateFAQ } from '@/libs/Api/Faq'
import { required } from '@validations'

export default {
  components: {
    BForm,
    ValidationProvider,
    ckEditorMain,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
    ValidationObserver,
    vSelect,
  },
  name: 'update Faq',
  title: 'update Faq',
  data() {
    return {
      required,
      showOverlay: false,
      panelTypes: [
        {
          name: 'Admin Panel',
          value: 1
        },
        {
          name: 'Pro Panel',
          value: 4
        },

        {
          name: 'All',
          value: 6
        },

      ],
      faqTypes: [
        {
          name: 'MarketPlace',
          value: 1
        },
        {
          name: 'FAQ',
          value: 2
        },
      ],
      faq: null,
    }
  },
  computed: {},
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      let _this = this
      _this.showOverlay = true
      let pageGetByIdRequest = new GetFAQById(_this)
      pageGetByIdRequest.setParams({ id: _this.$route.params.id })
      await pageGetByIdRequest.fetch(function (content) {
        _this.showOverlay = false
        _this.faq = content.data
      }, function (error) {
        _this.showOverlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    setEditorContent(content) {
      this.faq.description = content
    },
    async updateFAQ() {
      let _this = this
      Helper.validateForm(_this, 'updateFAQ', async () => {
        _this.showOverlay = true
        let updateFAQ = new UpdateFAQ(_this)
        updateFAQ.setParams({id:_this.$route.params.id})
        updateFAQ.setPayload(_this.faq)
        await updateFAQ.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.$router.push('/apps/site-setting/faqs/faqs')
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },

    async copyText() {
      await navigator.clipboard.writeText(this.data.seourl)
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `Copied`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
  }
}
</script>

<style scoped>

</style>
